import { atom } from "jotai"
import cookies from "js-cookie"
import ky, { HTTPError, Options } from "ky"
import { atomWithQuery, atomWithMutation } from "jotai-tanstack-query"

import { debug } from "~/lib/logger.client"
import { getLegacyLocalStorageItem, setLegacyLocalStorageItem } from "~/lib/storage.client"

import type { SessionResponse } from "~/api/session"

import type { LogoutResponse } from "~/api/logout"
import type { LoginRequest, LoginResponse } from "~/api/login"
import type { RegisterRequest, RegisterResponse } from "~/api/register"
import type { ActivateRequest, ActivateResponse } from "~/api/activate"
import type { ResetPasswordRequest, ResetPasswordResponse } from "~/api/reset-password"
import type { ForgotPasswordRequest, ForgotPasswordResponse } from "~/api/forgot-password"

const d = debug.extend("atoms:session")

/**
 * If the user has an access token in their local storage, set
 * the legacy-customer-access-token cookie, so that the session
 * endpoint will keep the customer logged in, and return a fresh
 * HttpOnly cookie.
 *
 * @todo Remove when most legacy tokens have reasonably been migrated
 * to HttpOnly cookies. If a customer has not been migrated at removal
 * they will need to login again.
 */
function setLegacyAccessTokenCookie() {
  // Get legacy token from local storage.
  const item = getLegacyLocalStorageItem("headless:customer")
  const accessToken = item?.accessToken
  if (!accessToken) return

  // Set session cookie, removed when browser closed.
  d("Resolved legacy customer access token, setting cookie.", { accessToken })
  cookies.set("legacy-customer-access-token", accessToken)
}

/**
 * Response from /api/session endpoint.
 */
export const sessionAtom = atomWithQuery(() => ({
  queryKey: ["session"],
  queryFn: async () => {
    // Set legacy token to cookie before request.
    setLegacyAccessTokenCookie()

    // Send API request.
    d("Sending request to /api/session.")
    const res = await ky.get("/api/session").json<SessionResponse>()
    d("Received response from /api/session", { res })

    // Return response.
    return res
  },
}))

/**
 * Status of session.
 */
export const sessionStatusAtom = atom(get => {
  const { status } = get(sessionAtom)
  return status
})

/**
 * Customer from session.
 */
export const sessionCustomerAtom = atom(get => {
  const { data } = get(sessionAtom)
  return data?.customer ?? null
})

/**
 * Session ID from session.
 */
export const sessionIdAtom = atom(get => {
  const { data } = get(sessionAtom)
  return data?.sessionId ?? null
})

/**
 * Login a session.
 * Ensure you do a hard navigate/refresh after this completes, as sessions use cookies
 * which need to be sent with the document request.
 */
export const loginAtom = atomWithMutation(() => ({
  mutationKey: ["login"],
  mutationFn: async (data: LoginRequest) => {
    // Send API request.
    d("Sending request to /api/login.")
    const res = await ky.post("/api/login", { hooks, json: data }).json<LoginResponse>()
    d("Received response from /api/login", { res })

    // Get token from response.
    const { accessToken, expiresAt } = res

    // Set legacy local storage token.
    setLegacyLocalStorageItem("headless:customer", { accessToken, expiresAt })

    // Return response.
    return res
  },
}))

/**
 * Logout the current session.
 * Ensure you do a hard navigate/refresh after this completes, as sessions use cookies
 * which need to be sent with the document request.
 */
export const logoutAtom = atomWithMutation(() => ({
  mutationKey: ["logout"],
  mutationFn: async () => {
    // Send API request.
    d("Sending request to /api/logout.")
    const res = await ky.post("/api/logout", { hooks }).json<LogoutResponse>()
    d("Received response from /api/logout", { res })

    // Remove legacy token from local storage.
    localStorage.removeItem("headless:customer")

    // Return response.
    return res
  },
}))

/**
 * Register a new account.
 * Ensure you do a hard navigate/refresh after this completes, as sessions use cookies
 * which need to be sent with the document request.
 */
export const registerAtom = atomWithMutation(() => ({
  mutationKey: ["register"],
  mutationFn: async (data: RegisterRequest) => {
    // Send API request.
    d("Sending request to /api/register.")
    const res = await ky.post("/api/register", { hooks, json: data }).json<RegisterResponse>()
    d("Received response from /api/register", { res })

    // Get token from response.
    const { accessToken, expiresAt } = res

    // Set legacy local storage token.
    setLegacyLocalStorageItem("headless:customer", { accessToken, expiresAt })

    // Return response.
    return res
  },
}))

/**
 * Initiate a "forgot password" request.
 */
export const forgotPasswordAtom = atomWithMutation(() => ({
  mutationKey: ["forgotPassword"],
  mutationFn: async (data: ForgotPasswordRequest) => {
    // Send API request.
    d("Sending request to /api/forgot-password.")
    const res = await ky.post("/api/forgot-password", { hooks, json: data }).json<ForgotPasswordResponse>()
    d("Received response from /api/forgot-password", { res })

    // Return response.
    return res
  },
}))

/**
 * Reset password.
 * Ensure you do a hard navigate/refresh after this completes, as sessions use cookies
 * which need to be sent with the document request.
 */
export const resetPasswordAtom = atomWithMutation(() => ({
  mutationKey: ["resetPassword"],
  mutationFn: async (data: ResetPasswordRequest) => {
    // Send API request.
    d("Sending request to /api/reset-password.")
    const res = await ky.post("/api/reset-password", { hooks, json: data }).json<ResetPasswordResponse>()
    d("Received response from /api/reset-password", { res })

    // Get token from response.
    const { accessToken, expiresAt } = res

    // Set legacy local storage token.
    setLegacyLocalStorageItem("headless:customer", { accessToken, expiresAt })

    // Return response.
    return res
  },
}))

/**
 * Activate a customer account.
 * Ensure you do a hard navigate/refresh after this completes, as sessions use cookies
 * which need to be sent with the document request.
 */
export const activateAtom = atomWithMutation(() => ({
  mutationKey: ["activate"],
  mutationFn: async (data: ActivateRequest) => {
    // Send API request.
    d("Sending request to /api/activate.")
    const res = await ky.post("/api/activate", { hooks, json: data }).json<ActivateResponse>()
    d("Received response from /api/activate", { res })

    // Get token from response.
    const { accessToken, expiresAt } = res

    // Set legacy local storage token.
    setLegacyLocalStorageItem("headless:customer", { accessToken, expiresAt })

    // Return response.
    return res
  },
}))

/**
 * Hooks for ky requests.
 */
const hooks: Options["hooks"] = {
  beforeError: [
    /**
     * Hook for extracting errors from responses.
     * @see https://github.com/sindresorhus/ky#hooksbeforeerror
     */
    async (error: HTTPError) => {
      try {
        const errorJson = await error.response.json()
        if (errorJson && "object" === typeof errorJson && "errors" in errorJson && Array.isArray(errorJson.errors)) {
          error.messages = errorJson.errors as string[]
        }
        return error
      } catch {
        return error
      }
    },
  ],
}

/**
 * Augment Error to include an optional array of messages.
 * This allows us to include multiple error messages in a single thrown error object.
 */
declare global {
  interface Error {
    messages?: string[]
  }
}
